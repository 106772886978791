import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Section from '../common/Section';
import Card from 'react-bootstrap/Card';
import styles from "./WelcomeSection.module.scss";
import medicalTourism from '../../../assets/images/medical-tourism.jpg';

 function WelcomeSection(): ReactElement {
    const { t } = useTranslation();
    return (
        <Section>
            <Card className={styles.message}>
                <Card.Body>
                <Card.Title className={styles.title}>{t("Welcome.Title", {brand: t("Brand")})}</Card.Title>
                <Card.Text className={styles.messageContent}>
                    {t("Welcome.Content")}
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className={styles.imageContainer}>
                    <Card.Img src={medicalTourism}  />
                    <Card.ImgOverlay>
                    <Row xs={1} md={2} className="g-4">
                        <Col key={1}>
                        </Col>
                    </Row>
                </Card.ImgOverlay>
            </Card>
        </Section>
    );
}

export default WelcomeSection;