import Header from './views/components/common/Header';
import Footer from './views/components/common/Footer';
import ContactUsSection from './views/components/sections/ContactUsSection';
import WelcomeSection from './views/components/sections/WelcomeSection';
import TreatmentSection from './views/components/sections/TreatmentsSection';
import DestinationSection from './views/components/sections/DestinationsSection';
import ReviewsSection from './views/components/sections/ReviewsSection';
import WhyChooseUsSection from './views/components/sections/WhyChooseUsSection';
import ProcessSection from './views/components/sections/ProcessSection';
import SpecialtiesSection from './views/components/sections/SpecialtiesSection';
import InstantMessenger from './views/components/common/InstantMessenger';
import MedicalSpecialistsSection from './views/components/sections/MedicalSpecialistsSection';
import FAQSection from './views/components/sections/FAQSection';
import AboutUs from './views/components/sections/AboutUs';

function App() {
  return (
      <div className="App">
        <Header/>
        <WelcomeSection />
        <AboutUs />
        <WhyChooseUsSection />
        <SpecialtiesSection />
        <ProcessSection />
        <TreatmentSection />
        <DestinationSection />
        <ReviewsSection />
        <FAQSection />
        <ContactUsSection />
        <Footer/>
        <InstantMessenger />
      </div>
  );
}

export default App;
